import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"

const RegistrationFromSMSPage: React.FC<{guid: string }> = ({guid }) => {
  useEffect(() => {

    navigate("/check-registration")
  }, [])

  return null
}

export default RegistrationFromSMSPage
